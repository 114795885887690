import React from 'react';
import styled from '@emotion/styled';
import { GrGatsbyjs,  } from "react-icons/gr";
import netlifyLogo from "../../static/logo/logo-netlify.svg"

const Wrapper = styled.footer`
  position: relative;
  padding-top: 2rem;
  bottom: 0;
  box-shadow: ${props => props.theme.shadow.footer};
  background: ${props => props.theme.gradient.leftToRight};
  font-family: ${props => props.theme.fontFamily.body};
  font-weight: 500;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding-top: 1.4rem;
  }
`;

const Text = styled.div`
  margin: 0;
  padding-bottom: 2rem;
  text-align: center;
  color: ${props => props.theme.colors.white.light};
`;

const Footer = () => (
  <Wrapper>
    <Text>
      <span>
       <h4>Propulsé par :
         <a target="_blank" href="https://www.gatsbyjs.org/"><GrGatsbyjs style={{'color':'#663399!important', 'font-size':'23px', 'margin':'-4px 8px'}}/></a>
          + 
          <a target="_blank" href="https://www.netlify.com/"><img style={{'margin':'-4px 8px', 'width' : '25px'}} src={netlifyLogo}/></a>
          </h4>Copyrights © 2024 -{' '}
        <a target="_blank" href="/about" style={{'color':'white'}}> Drupal Web Academy </a> - by  -<a target="_blank" href="https://www.octoclic.com/" style={{'color':'#A066CB','fontWeight':'bold','textShadow':'black 1px 1px 1.05px'}}> Octoclic Agency </a>
      </span>
    </Text>
  </Wrapper>
);
export default Footer;
